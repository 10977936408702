import * as React from "react"
import { Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

// const CategoryList = ({
//   pageContext: { category },
//   data: { allMarkdownRemark },
// }) => (
//   <div>
//     <h1>{category} Articles</h1>

//     <ul>
//       {allMarkdownRemark.edges.map(({ node }) => {
//         return (
//           <li key={node.frontmatter.title}>
//             <Link to={`article/${node.frontmatter.slug}`}>
//               {node.frontmatter.title}
//             </Link>
//             <div>
//               <time>{node.frontmatter.date}</time>
//             </div>
//           </li>
//         )
//       })}
//     </ul>
//   </div>
// )

// export default CategoryList

// export const query = graphql`
//   query CategoryListQuery($ids: [String]!) {
//     allMarkdownRemark(filter: { id: { in: $ids } }) {
//       edges {
//         node {
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             date(formatString: "MMM D, YYYY")
//           }
//         }
//       }
//     }
//   }
// `

// original from blog-post

// const BlogPostTemplate = ({
//   data: { authorImage, coverImage },
//   pageContext: { nextPost, page, previousPost },
//   location,

const CategoryList = ({
  data,
  pageContext: { name, headline, description, seo, posts },
  location,
  // // pageContext: { category },
  // pageContext: { id },
  // data: { allMarkdownRemark },
  // location,
}) => {
  // const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  // const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      {/* <Seo title={category} description={category} /> */}
      <div>
        <h1>{name} Articles</h1>
        <h2>{headline}</h2>
        <p>{description}</p>

        <ul>
          {posts.map(post => {
            return (
              <li>
                <Link to={post.slug}>{post.title}</Link>
              </li>
            )
          })}
        </ul>
      </div>
    </Layout>
  )
}

export default CategoryList
// query CategoryListQuery($ids: [String]!) {
export const query = graphql`
  query CategoryListQuery {
    site {
      siteMetadata {
        title
      }
    }
    allGraphCmsCategory {
      edges {
        node {
          id
          title: name
          slug
          headline
          seo {
            id
            image {
              url
            }
            keywords
            description
          }
          posts {
            author {
              id
              name
            }
            id
            excerpt
            slug
            title
            updatedAt
          }
        }
      }
    }
  }
`

// category(where: { id: "cku0vesc06wfq0b237lltwd4o" }) {
//   id
//   title: name
//   slug
//   headline
//   seo {
//     id
//     keywords
//     description
//     image {
//       id
//       url
//     }
//   }
// }

// // New
// export const query = graphql`
//   query CategoryListQuery($ids: [String]!) {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//     allMarkdownRemark(filter: { id: { in: $ids } }) {
//       edges {
//         node {
//           fields {
//             slug
//           }
//           frontmatter {
//             title
//             date(formatString: "MMM D, YYYY")
//           }
//         }
//       }
//     }
//   }
// `

// export default CategoryPageTemplate

// export const pageQuery = graphql`
//   query {
//     site {
//       siteMetadata {
//         title
//       }
//     }

//     allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
//       nodes {
//         excerpt
//         fields {
//           slug
//         }
//         frontmatter {
//           date(formatString: "MMMM DD, YYYY")
//           title
//           description
//         }
//       }
//     }
//   }
// `
